import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useCommon } from 'models/common';

import Link from 'components/atoms/Link';

import styles from './index.css';

const Navigator = ({ className, activeClassName, children, itemOnClick, useActive = true }) => {
	const { t } = useTranslation(['common']);
	const [{ activePage }] = useCommon();

	return (
		<nav className={classnames(styles.navigator, className)}>
			<ul>
				<li
					className={
						useActive && (activePage === 'brands' || activePage === 'brandsDetail')
							? classnames(styles.active, activeClassName)
							: ''
					}
				>
					<Link to="/brands" onClick={itemOnClick}>
						{t('brands')}
					</Link>
				</li>
				<li
					className={
						useActive && (activePage === 'blog' || activePage === 'blogDetail')
							? classnames(styles.active, activeClassName)
							: ''
					}
				>
					<Link to="/blog" onClick={itemOnClick}>
						{t('blog')}
					</Link>
				</li>
				<li
					className={
						useActive && activePage === 'about' ? classnames(styles.active, activeClassName) : ''
					}
				>
					<Link to="/about" onClick={itemOnClick}>
						{t('about')}
					</Link>
				</li>
				<li
					className={
						useActive && activePage === 'faqs' ? classnames(styles.active, activeClassName) : ''
					}
				>
					<Link to="/faqs" onClick={itemOnClick}>
						{t('faqs')}
					</Link>
				</li>
				{children}
			</ul>
		</nav>
	);
};

export default Navigator;
