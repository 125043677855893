import React from 'react';
import classnames from 'classnames';

import Arrow from 'images/icon/ig-arrow.inline.svg';

import Link from 'components/atoms/Link';

import styles from './index.css';

const InstagramCard = ({ className, link, image, content }) => (
	<Link to={link} newTab>
		<div className={classnames(styles.instagramCard, className)}>
			<img className={styles.image} src={image} alt="instagram" />
			<div className={styles.hover}>
				<span className={styles.text}>{content}</span>
				<Arrow />
			</div>
		</div>
	</Link>
);

export default InstagramCard;
