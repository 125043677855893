import { createAction, handleActions } from 'redux-actions';

import { wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

export const getHome = createAction('FETCH_HOME', async () => {
	const { status, message, data } = await wrapLangFetch('homepage', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

const reducer = {
	home: handleActions(
		{
			FETCH_HOME_FULFILLED: (state, action) => ({
				...state,
				banners: action.payload.banners,
				brands: action.payload.brands,
				reviews: action.payload.posts,
			}),
		},
		{
			banners: [],

			brands: [],

			reviews: [],
		},
	),
};

const selectHome = state => ({
	...state.home,
});

export const useHome = () => useRedux(selectHome);

export default { reducer };
