import React from 'react';

import { useHistory } from 'models/routing';
import { useLanguage } from 'models/i18n';

const onClickHandler = (callback, history, newTab) => {
	return e => {
		if (!newTab) {
			e.preventDefault();
			history.push(`${e.currentTarget.pathname}${e.currentTarget.search}`);
		}
		callback(e);
	};
};

const Link = ({ className, to = '', onClick = () => {}, children, newTab = false, ...props }) => {
	const [{ lan }] = useLanguage();
	const history = useHistory();

	return (
		<a
			className={className}
			// eslint-disable-next-line no-nested-ternary
			href={newTab ? to : `/${lan}${to}`}
			target={newTab ? '_blank' : '_self'}
			role="button"
			tabIndex={0}
			onClick={onClickHandler(onClick, history, newTab)}
			onKeyPress={() => {}}
			/* eslint-disable react/jsx-props-no-spreading */
			{...props}
		>
			{children}
		</a>
	);
};

export default Link;
