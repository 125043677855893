import React from 'react';
import classnames from 'classnames';

import styles from './index.css';

const Button = ({
	// props
	className,
	type = '',
	label,
	onClick = () => {},
	children,
}) => (
	<button
		className={classnames(styles.button, className, {
			[styles.normal]: type === 'normal',
			[styles.outline]: type === 'outline',
			[styles.outlinePrimaryColor]: type === 'outlinePrimaryColor',
		})}
		type="button"
		onClick={onClick}
	>
		{children}
		{label && <span>{label}</span>}
	</button>
);

export default Button;
