/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import CloseIcon from 'images/icon/close.inline.svg';

import Navigation from 'components/molecules/Navigation';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
// import Translate from 'components/molecules/Translate';

import styles from './index.css';

const SideMenu = ({ className, close }) => {
	const { t } = useTranslation(['common']);

	return (
		<div className={classnames(styles.sideMenu, className)}>
			<div className={styles.content}>
				<div className={styles.top}>
					<Button className={styles.close} onClick={close}>
						<CloseIcon />
					</Button>
				</div>
				<div className={styles.mid}>
					<Navigation
						className={styles.nav}
						itemOnClick={close}
						activeClassName={styles.activeClassName}
					>
						{/* <li>
							<Link to="https://infinitykitchen.surveycake.biz/s/eLGWV" newTab onClick={close}>
								{t('contact')}
							</Link>
						</li> */}
					</Navigation>
					{/* <Translate className={styles.translate} /> */}
				</div>
				<div className={styles.bottom}>
					<Link to="http://stores.infinitykitchen.com/" newTab className={styles.order}>
						<Button label={t('order')} className={styles.order} type="normal" />
					</Link>
				</div>
			</div>
			<div
				className={styles.closeArea}
				onClick={close}
				role="button"
				tabIndex={0}
				onKeyPress={() => {}}
			/>
		</div>
	);
};

export default SideMenu;
