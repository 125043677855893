import React from 'react';
import classnames from 'classnames';

import Link from 'components/atoms/Link';
import LogoIcon from 'images/icon/logo.inline.svg';

const Logo = ({ className, onClick }) => (
	<Link to="/" onClick={onClick} className={className}>
		<LogoIcon className={classnames(className)} />
	</Link>
);

export default Logo;
