import { createAction, handleActions } from 'redux-actions';

import { wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

import { storeController, abortController } from './controllers';

const clearBrandsDetailData = createAction('CLEAR_BRANDS_DETAIL_DATA');

export const getBrands = createAction('FETCH_BRANDS', async () => {
	const { status, message, data } = await wrapLangFetch('brand', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.brands;
});

export const getBrandsDetail = createAction(
	'FETCH_BRANDS_DETAIL',
	id => async (dispatch, getState) => {
		const {
			controllers: { brandsDetail: pageControllers },
		} = getState();

		// abort previous controller
		if ('FETCH_BRANDS_DETAIL' in pageControllers) {
			await dispatch(abortController('brandsDetail', 'FETCH_BRANDS_DETAIL'));
		}
		// new controller
		const controller = new AbortController();
		const { signal } = controller;
		await dispatch(storeController('brandsDetail', 'FETCH_BRANDS_DETAIL', controller));

		const { data, status, message } = await wrapLangFetch(`brand/${id}`, {
			method: 'GET',
			signal,
		});

		if (status !== 200) {
			throw new Error(message);
		}

		return { data, id, activeMenuId: data.menu[0].id };
	},
);

export const getMenuDetail = createAction('FETCH_MENU_DETAIL', () => async (dispatch, getState) => {
	const {
		controllers: { brandsMenu: pageControllers },
		brands: {
			brandsDetail: { activeMenuId, id },
		},
	} = getState();

	// abort previous controller
	if ('FETCH_MENU_DETAIL' in pageControllers) {
		await dispatch(abortController('brandsMenu', 'FETCH_MENU_DETAIL'));
	}
	// new controller
	const controller = new AbortController();
	const { signal } = controller;
	await dispatch(storeController('brandsMenu', 'FETCH_MENU_DETAIL', controller));

	const { data, status, message } = await wrapLangFetch(
		`brand/${id}/menu/${activeMenuId}`,
		{
			method: 'GET',
			signal,
		},
		{
			page: 1,
		},
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return { menu: data.products.data, id: activeMenuId, totalPage: data.products.last_page };
});

export const updateMenuDetail = createAction(
	'UPDATE_MENU_DETAIL',
	() => async (dispatch, getState) => {
		const {
			controllers: { brandsMenuUpdata: pageControllers },
			brands: {
				brandsDetail: { activeMenuId, currentPage, id },
			},
		} = getState();

		// abort previous controller
		if ('UPDATE_MENU_DETAIL' in pageControllers) {
			await dispatch(abortController('brandsMenuUpdata', 'UPDATE_MENU_DETAIL'));
		}
		// new controller
		const controller = new AbortController();
		const { signal } = controller;
		await dispatch(storeController('brandsMenuUpdata', 'UPDATE_MENU_DETAIL', controller));

		const { data, status, message } = await wrapLangFetch(
			`brand/${id}/menu/${activeMenuId}`,
			{
				method: 'GET',
				signal,
			},
			{
				page: currentPage + 1,
			},
		);

		if (status !== 200) {
			throw new Error(message);
		}

		return { menu: data.products.data, currentPage: data.products.current_page };
	},
);

export const changeActiveMenuId = createAction('CHANGE_ACTIVE_MENU_ID', menuId => menuId);

export const initializeBrandsDetail = createAction(
	'INITIALIZE_BRANDS_DETAIL',
	id => async dispatch => {
		await dispatch(clearBrandsDetailData());
		await dispatch(getBrandsDetail(id));
		await dispatch(getMenuDetail());
	},
);

const reducer = {
	brands: handleActions(
		{
			CLEAR_BRANDS_DETAIL_DATA: state => ({
				...state,

				brandsDetail: {
					loading: false,
					error: false,
					id: 0,
					activeMenuId: 0,
					info: {},
					menu: [],
					totalPage: 0,
					currentPage: 0,
				},
			}),

			FETCH_BRANDS_FULFILLED: (state, action) => ({
				...state,

				brandsList: action.payload,
			}),

			FETCH_BRANDS_DETAIL_FULFILLED: (state, action) => ({
				...state,

				brandsDetail: {
					...state.brandsDetail,

					id: action.payload.id,
					activeMenuId: action.payload.activeMenuId,
					info: action.payload.data,
				},
			}),

			FETCH_MENU_DETAIL_FULFILLED: (state, action) => ({
				...state,

				brandsDetail: {
					...state.brandsDetail,

					activeMenuId: action.payload.id,
					menu: action.payload.menu,
					currentPage: 1,
					totalPage: action.payload.totalPage,
				},
			}),

			UPDATE_MENU_DETAIL_PENDING: state => ({
				...state,

				brandsDetail: {
					...state.brandsDetail,

					loading: true,
					error: false,
				},
			}),

			UPDATE_MENU_DETAIL_FULFILLED: (state, action) => ({
				...state,

				brandsDetail: {
					...state.brandsDetail,

					loading: false,
					menu: [...state.brandsDetail.menu, ...action.payload.menu],
					currentPage: action.payload.currentPage,
				},
			}),

			CHANGE_ACTIVE_MENU_ID: (state, action) => ({
				...state,

				brandsDetail: {
					...state.brandsDetail,

					activeMenuId: action.payload,
				},
			}),
		},
		{
			brandsList: [],

			brandsDetail: {
				loading: false,
				error: false,
				id: 0,
				activeMenuId: 0,
				info: {},
				menu: [],
				totalPage: 0,
				currentPage: 0,
			},
		},
	),
};

const selectBrands = state => ({
	...state.brands,
});

export const useBrands = () =>
	useRedux(selectBrands, {
		getMenuDetail,
		updateMenuDetail,
		changeActiveMenuId,
	});

export default { reducer };
