import React from 'react';

import { storeActivePage } from 'models/common';
import { initializeBrandsDetail } from 'models/brands';

export default {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'brandsDetail' */ './component')],
	render: ([Detail]) => <Detail />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter brands detail');
		await store.dispatch(initializeBrandsDetail(id));
		await store.dispatch(storeActivePage('brandsDetail'));
		console.log('on Enter brands detail / end');
	},
};
