import React from 'react';

import { storeActivePage } from 'models/common';
import { initializeBlogDetail } from 'models/blog';

export default {
	path: '/:id',
	components: () => [import(/* webpackChunkName: 'blogDetail' */ './component')],
	render: ([Detail]) => <Detail />,
	onEnter: async ({ store, params: { id } }) => {
		console.log('on Enter blog detail');
		await store.dispatch(initializeBlogDetail(id));
		await store.dispatch(storeActivePage('blogDetail'));
		console.log('on Enter blog detail / end');
	},
};
