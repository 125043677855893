import React from 'react';

import { storeActivePage, getSEO } from 'models/common';
import { getBrands } from 'models/brands';

import DetailRoute from './Detail';

export default {
	path: '/brands',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'brands' */ './component')],
			render: ([Brands]) => <Brands />,
			onEnter: async ({ store }) => {
				console.log('on Enter brands');
				await store.dispatch(storeActivePage('brands'));
				await store.dispatch(getSEO('brand'));
				await store.dispatch(getBrands());
				console.log('on Enter brands / end');
			},
		},
		DetailRoute,
	],
};
