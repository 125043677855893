import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import MenuIcon from 'images/icon/menu.inline.svg';

import SideMenu from 'components/organisms/SideMenu';
import Navigation from 'components/molecules/Navigation';
import Logo from 'components/atoms/Logo';
import Button from 'components/atoms/Button';
import Link from 'components/atoms/Link';
// import Translate from 'components/molecules/Translate';

import { useCommon } from 'models/common';

import { useScroll } from 'util/hook/useScroll';

import styles from './index.css';

const Header = ({ className }) => {
	const { t } = useTranslation(['common']);
	const [{ activePage }] = useCommon();
	const [fixedHeader, setFixedHeader] = useState(false);
	const [hideHeader, setHideHeader] = useState(false);
	const [isAtBottom, setIsAtBottom] = useState(false);
	const [openSideMenu, setOpenSideMenu] = useState(false);
	const scrollY = useScroll();

	useEffect(() => {
		if (scrollY <= 104) {
			setFixedHeader(false);
			setHideHeader(false);
			setIsAtBottom(false);
		} else if (scrollY > 104 && scrollY <= 134) {
			setFixedHeader(false);
			setHideHeader(true);
			setIsAtBottom(false);
		} else if (scrollY > 134 && scrollY < 154) {
			setFixedHeader(false);
			setHideHeader(true);
			setIsAtBottom(false);
		} else if (scrollY + window.innerHeight === document.body.offsetHeight) {
			setFixedHeader(false);
			setHideHeader(true);
			setIsAtBottom(true);
		} else {
			setFixedHeader(true);
			setHideHeader(false);
			setIsAtBottom(false);
		}
	}, [scrollY]);

	const toggleMenu = () => {
		document.getElementsByTagName('body')[0].classList.toggle('no-scroll');
		setOpenSideMenu(!openSideMenu);
	};

	return (
		<>
			<header
				className={classnames(
					styles.header,
					{
						[styles.isNotAtHomePage]: activePage !== 'home',
						[styles.fixedHeader]: fixedHeader && activePage === 'home',
						[styles.hideHeader]: hideHeader && activePage === 'home',
						[styles.isAtBottom]: isAtBottom && activePage === 'home',
					},
					className,
				)}
			>
				<div className={styles.left}>
					<Button className={styles.menu} onClick={toggleMenu}>
						<MenuIcon />
					</Button>
					<Logo className={styles.logo} />
					<Navigation className={styles.nav}>
						{/* <li>
							<Link to="https://infinitykitchen.surveycake.biz/s/eLGWV" newTab>
								{t('contact')}
							</Link>
						</li> */}
					</Navigation>
				</div>
				<div className={styles.right}>
					{/* <Translate className={styles.translate} /> */}
					<Link to="http://stores.infinitykitchen.com/" newTab>
						<Button label={t('order')} className={styles.order} type="normal" />
					</Link>
				</div>
			</header>
			<SideMenu
				className={classnames(styles.sideMenu, {
					[styles.openSideMenu]: openSideMenu,
				})}
				close={toggleMenu}
			/>
		</>
	);
};

export default Header;
