import { createAction, handleActions } from 'redux-actions';
import { useRedux } from 'util/hook/redux';
import { wrapLangFetch } from 'util/api';

import { storeController, abortController } from './controllers';

export const cleanBlog = createAction('CLEAN_BLOG');

export const getBlog = createAction('GET_BLOG', tagId => async (dispatch, getState) => {
	const {
		controllers: { blogUpdata: pageControllers },
	} = getState();

	// abort previous controller
	if ('UPDATE_BLOG' in pageControllers) {
		await dispatch(abortController('blogUpdata', 'UPDATE_BLOG'));
	}

	// new controller
	const controller = new AbortController();
	const { signal } = controller;
	await dispatch(storeController('blogUpdata', 'UPDATE_BLOG', controller));

	const { data, status, message } = await wrapLangFetch(
		'post',
		{
			method: 'GET',
			signal,
		},
		{
			page: 1,
			tag_id: tagId,
		},
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return {
		totalPage: data.post.last_page,
		currentPage: 1,
		list: data.post.data,
		tagList: data.tags,
		tagId,
	};
});

export const getBlogDetail = createAction('GET_BLOG_DETAIL', id => async () => {
	const { data, status, message } = await wrapLangFetch(`post/${id}`, {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data;
});

export const updateBlog = createAction('UPDATE_BLOG', () => async (dispatch, getState) => {
	const {
		controllers: { blogUpdata: pageControllers },
		blog: {
			post: { tagId, currentPage },
		},
	} = getState();

	// abort previous controller
	if ('UPDATE_BLOG' in pageControllers) {
		await dispatch(abortController('blogUpdata', 'UPDATE_BLOG'));
	}
	// new controller
	const controller = new AbortController();
	const { signal } = controller;
	await dispatch(storeController('blogUpdata', 'UPDATE_BLOG', controller));

	const { data, status, message } = await wrapLangFetch(
		'post',
		{
			method: 'GET',
			signal,
		},
		{
			page: currentPage + 1,
			tag_id: tagId,
		},
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return { currentPage: data.post.current_page, list: data.post.data };
});

export const initializeBlog = createAction('INITIALIZE_BLOG', tagId => async dispatch => {
	await dispatch(cleanBlog());
	await dispatch(getBlog(tagId));
});

export const initializeBlogDetail = createAction('INITIALIZE_BLOG_DETAIL', id => async dispatch => {
	await dispatch(getBlogDetail(id));
});

const reducer = {
	blog: handleActions(
		{
			GET_BLOG_PENDING: state => ({
				...state,

				loading: true,
			}),

			GET_BLOG_FULFILLED: (state, action) => ({
				...state,

				post: {
					...state.post,

					postList: action.payload.list,
					totalPage: action.payload.totalPage,
					currentPage: action.payload.currentPage,
					tagList: action.payload.tagList,
					tagId: action.payload.tagId,
				},
				loading: false,
			}),

			UPDATE_BLOG_PENDING: state => ({
				...state,

				loading: true,
			}),

			UPDATE_BLOG_FULFILLED: (state, action) => ({
				...state,

				post: {
					...state.post,

					postList: [...state.post.postList, ...action.payload.list],
					currentPage: action.payload.currentPage,
				},
				loading: false,
			}),

			GET_BLOG_DETAIL_PENDING: state => ({
				...state,

				loading: true,
			}),

			GET_BLOG_DETAIL_FULFILLED: (state, action) => ({
				...state,

				postDetail: action.payload,
				loading: false,
			}),

			CLEAN_BLOG: state => ({
				...state,

				post: {
					postList: [],
					totalPage: 0,
					currentPage: 0,
					tagList: [],
					tagId: '',
				},
				postDetail: {},
			}),
		},
		{
			loading: false,
			error: false,
			post: {
				postList: [],
				totalPage: 0,
				currentPage: 0,
				tagList: [],
				tagId: '',
			},
			postDetail: {},
		},
	),
};

const selectBlog = state => ({
	...state.blog,
});

export const useBlog = () => useRedux(selectBlog, { updateBlog, getBlog });

export default { reducer };
