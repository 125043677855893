import React from 'react';

import { storeActivePage, getSEO } from 'models/common';
import { initializeFAQsDetail } from 'models/faqs';

export default {
	path: '/faqs',
	components: () => [import(/* webpackChunkName: 'faqs' */ './component')],
	render: ([FAQs]) => <FAQs />,
	onEnter: async ({ store }) => {
		console.log('on Enter faqs');
		await store.dispatch(storeActivePage('faqs'));
		await store.dispatch(getSEO('faq'));
		await store.dispatch(initializeFAQsDetail());
		console.log('on Enter faqs / end');
	},
};
