import React, { Component } from 'react';
import classnames from 'classnames';

import QRcodeIcon from 'images/line/QRcode.png';

import styles from './index.css';

class LineChat extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
		};
	}

	openTab = url => {
		const a = window.document.createElement('a');
		a.target = '_blank';
		a.href = url;

		// Dispatch fake click
		const e = window.document.createEvent('MouseEvents');
		e.initMouseEvent(
			'click',
			true,
			true,
			window,
			0,
			0,
			0,
			0,
			0,
			false,
			false,
			false,
			false,
			0,
			null,
		);
		a.dispatchEvent(e);
	};

	openLineChat = () => {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		) {
			this.openTab('https://lin.ee/6094hoE');
		} else {
			this.setState({ open: true });
		}
	};

	closeLineChat = () => {
		this.setState({ open: false });
	};

	render() {
		const { className, style } = this.props;
		const { open } = this.state;

		return (
			<div className={classnames(styles.lineChat, className)} style={style}>
				<div
					className={classnames(styles.lineButton, {
						[styles.hidden]: open,
						[styles.fadeOut]: open,
						[styles.fadeIn]: !open,
					})}
					role="button"
					tabIndex="0"
					onClick={this.openLineChat}
					onKeyPress={this.openLineChat}
					aria-label="Open LineChat"
				/>
				<div
					className={classnames(styles.chatContainer, {
						[styles.open]: open,
						[styles.bounceInUp]: open,
						[styles.fadeOut]: !open,
					})}
				>
					<div className={styles.lineHeader}>
						<i className={styles.icon} />
						<span>LINE</span>
						<div
							className={styles.closeButton}
							role="button"
							tabIndex="0"
							onClick={this.closeLineChat}
							onKeyPress={this.closeLineChat}
							aria-label="Close Button"
						/>
					</div>
					<div className={styles.lineInner}>
						<div className={styles.image}>
							<img src={QRcodeIcon} alt="line_icon" />
						</div>
						<div className={styles.title}>加入無限廚房 LINE 好友</div>
						<div className={styles.description}>隨時掌握最新優惠訊息！</div>
					</div>
				</div>
			</div>
		);
	}
}

export default LineChat;
