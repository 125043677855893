import { combineReducers } from 'redux';

import routing from './routing';
import controllers from './controllers';
import blog from './blog';
import i18n from './i18n';
import brands from './brands';
import home from './home';
import common from './common';
import faqs from './faqs';

const reducers = combineReducers({
	...routing.reducer,
	...controllers.reducer,
	...blog.reducer,
	...i18n.reducer,
	...brands.reducer,
	...home.reducer,
	...common.reducer,
	...faqs.reducer,
});

export default reducers;
