import { createAction, handleActions } from 'redux-actions';

import { wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

const clearFAQsData = createAction('CLEAR_FAQS_DATA');

export const getFAQs = createAction('FETCH_FAQS', async () => {
	const { status, message, data } = await wrapLangFetch('qna', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return { data: data.qna };
});

export const initializeFAQsDetail = createAction('INITIALIZE_FAQS_DETAIL', () => async dispatch => {
	await dispatch(clearFAQsData());
	await dispatch(getFAQs());
});

const reducer = {
	faqs: handleActions(
		{
			FETCH_FAQS_FULFILLED: (state, action) => ({
				...state,

				list: action.payload.data,
			}),

			CLEAR_FAQS_DATA: state => ({
				...state,

				list: [],
			}),
		},
		{
			list: [],
		},
	),
};

const selectFAQs = state => ({
	...state.faqs,
});

export const useFAQs = () => useRedux(selectFAQs);

export default { reducer };
