import React from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { useCommon } from 'models/common';

import Link from 'components/atoms/Link';
import Navigation from 'components/molecules/Navigation';
import InstagramCard from 'components/molecules/InstagramCard';
import Button from 'components/atoms/Button';

import FBIcon from 'images/icon/facebook.inline.svg';
import IGIcon from 'images/icon/instagram.inline.svg';
// import ContactIcon from 'images/icon/contact.inline.svg';
import AddressIcon from 'images/icon/address.inline.svg';
import ClockIcon from 'images/icon/clock.inline.svg';
import PhoneIcon from 'images/icon/phone.inline.svg';

import { useMedia } from 'util/hook/useMedia';

import styles from './index.css';

const Footer = () => {
	const { t } = useTranslation(['common']);
	const [{ igpost, activePage }] = useCommon();
	const media = useMedia();
	const hideLocationInfo = media === 'phone' && activePage === 'about';

	return (
		<footer className={styles.footer}>
			<div className={styles.inner}>
				<div className={styles.leftInfo}>
					<div className={styles.instagramCards}>
						{igpost.map((item, index) => (
							<InstagramCard
								link={item.url}
								key={item.pic}
								image={item.pic}
								content={item.content}
								className={index < 3 ? styles.instagramCard : ''}
							/>
						))}
					</div>
					{media !== 'phone' && (
						<div className={classnames(styles.rightInfo, [styles.special])}>
							<Navigation className={styles.nav} useActive={false} />
							<div className={styles.icons}>
								<Link to="https://www.facebook.com/infinitykitchen2020" newTab>
									<div className={styles.icon}>
										<FBIcon />
									</div>
								</Link>
								<Link to="https://www.instagram.com/infinitykitchen2020/" newTab>
									<div className={classnames(styles.icon, styles.noMargin)}>
										<IGIcon />
									</div>
								</Link>
								{/* <Link to="https://infinitykitchen.surveycake.biz/s/eLGWV" newTab>
							<div className={classnames(styles.icon, styles.noMargin)}>
								<ContactIcon />
							</div>
						</Link> */}
								<div className={styles.order}>
									<Link to="http://stores.infinitykitchen.com/" newTab>
										<Button label={t('order')} type="normal" />
									</Link>
								</div>
							</div>
							<p className={styles.cotyRight}>© 無限廚房 ALL RIGHTS RESERVED</p>
						</div>
					)}
				</div>
				<div className={styles.rightInfo}>
					<div className={styles.locationBox}>
						<div className={hideLocationInfo ? styles.hide : ''}>
							<div className={styles.location}>
								<div className={styles.name}>{t('locationTitle-1')}</div>
								<div className={styles.infoBox}>
									<div className={styles.info}>
										<ClockIcon className={styles.icon} />
										11 : 00 - 22 : 00
									</div>
									<div className={styles.info}>
										<AddressIcon className={styles.icon} />
										<Link
											className={styles.link}
											to={`https://www.google.com/maps/search/?api=1&query=${t(
												'locationAddress-1',
											)}`}
											newTab
										>
											{t('locationAddress-1')}
										</Link>
									</div>
									<div className={styles.info}>
										<PhoneIcon className={styles.icon} />
										<a href="tel:+886-2-27552068">02-27552068</a>
									</div>
								</div>
							</div>
							<div className={styles.location}>
								<div className={styles.name}>{t('locationTitle-2')}</div>
								<div className={styles.infoBox}>
									<div className={styles.info}>
										<ClockIcon className={styles.icon} />
										10 : 30 - 22 : 00
									</div>
									<div className={styles.info}>
										<AddressIcon className={styles.icon} />
										<Link
											className={styles.link}
											to={`https://www.google.com/maps/search/?api=1&query=${t(
												'locationAddress-2',
											)}`}
											newTab
										>
											{t('locationAddress-2')}
										</Link>
									</div>
									<div className={styles.info}>
										<PhoneIcon className={styles.icon} />
										<a href="tel:+886-2-26592068">02-26592068</a>
									</div>
								</div>
							</div>
							<div className={styles.location}>
								<div className={styles.name}>{t('locationTitle-3')}</div>
								<div className={styles.infoBox}>
									<div className={styles.info}>
										<ClockIcon className={styles.icon} />
										10 : 30 - 22 : 00
									</div>
									<div className={styles.info}>
										<AddressIcon className={styles.icon} />
										<Link
											className={styles.link}
											to={`https://www.google.com/maps/search/?api=1&query=${t(
												'locationAddress-3',
											)}`}
											newTab
										>
											{t('locationAddress-3')}
										</Link>
									</div>
									<div className={styles.info}>
										<PhoneIcon className={styles.icon} />
										<a href="tel:+886-2-82271123">02-82271123</a>
									</div>
								</div>
							</div>
							<div className={styles.location}>
								<div className={styles.name}>{t('locationTitle-4')}</div>
								<div className={styles.infoBox}>
									<div className={styles.info}>
										<ClockIcon className={styles.icon} />
										11 : 00 - 22 : 00
									</div>
									<div className={styles.info}>
										<AddressIcon className={styles.icon} />
										<Link
											className={styles.link}
											to={`https://www.google.com/maps/search/?api=1&query=${t(
												'locationAddress-4',
											)}`}
											newTab
										>
											{t('locationAddress-4')}
										</Link>
									</div>
									<div className={styles.info}>
										<PhoneIcon className={styles.icon} />
										<a href="tel:+886-2-25622658">02-25622658</a>
									</div>
								</div>
							</div>
							<div className={styles.location}>
								<div className={styles.name}>{t('locationTitle-5')}</div>
								<div className={styles.infoBox}>
									<div className={styles.info}>
										<ClockIcon className={styles.icon} />
										11 : 00 - 22 : 00
									</div>
									<div className={styles.info}>
										<AddressIcon className={styles.icon} />
										<Link
											className={styles.link}
											to={`https://www.google.com/maps/search/?api=1&query=${t(
												'locationAddress-5',
											)}`}
											newTab
										>
											{t('locationAddress-5')}
										</Link>
									</div>
									<div className={styles.info}>
										<PhoneIcon className={styles.icon} />
										<a href="tel:+886-2-25280908">02-25280908</a>
									</div>
								</div>
							</div>
						</div>
						{media === 'phone' && (
							<>
								<Navigation className={styles.nav} useActive={false} />
								<div className={styles.icons}>
									<Link to="https://www.facebook.com/infinitykitchen2020" newTab>
										<div className={styles.icon}>
											<FBIcon />
										</div>
									</Link>
									<Link to="https://www.instagram.com/infinitykitchen2020/" newTab>
										<div className={classnames(styles.icon, styles.noMargin)}>
											<IGIcon />
										</div>
									</Link>
									{/* <Link to="https://infinitykitchen.surveycake.biz/s/eLGWV" newTab>
							<div className={classnames(styles.icon, styles.noMargin)}>
								<ContactIcon />
							</div>
						</Link> */}
									<div className={styles.order}>
										<Link to="http://stores.infinitykitchen.com/" newTab>
											<Button label={t('order')} type="normal" />
										</Link>
									</div>
								</div>
								<p className={styles.cotyRight}>© 無限廚房 ALL RIGHTS RESERVED</p>
							</>
						)}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
