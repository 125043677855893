import React from 'react';

import { getHome } from 'models/home';
import { getIGPost, storeActivePage, getSEO } from 'models/common';

import BlogRoute from './Blog';
import BrandsRoute from './Brands';
import FAQsRoute from './FAQs';
import AboutRoute from './About';

const routes = {
	path: '',
	components: () => [],
	render: (_, children) => children,
	onEnter: async ({ store }) => {
		console.log('on Enter');
		window.scrollTo(0, 0);
		const {
			common: { igpost },
		} = store.getState();
		if (igpost.length === 0) {
			store.dispatch(getIGPost());
		}
		console.log('on Enter / end');
	},
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'home' */ './Home')],
			render: ([Home]) => <Home />,
			onEnter: async ({ store }) => {
				console.log('on Enter home');
				await store.dispatch(storeActivePage('home'));
				await store.dispatch(getSEO('home'));
				await store.dispatch(getHome());
				console.log('on Enter home / end');
			},
		},
		{
			path: '/:lang',
			components: () => [],
			render: (_, children) => children,
			children: [
				{
					path: '',
					components: () => [import(/* webpackChunkName: 'home' */ './Home')],
					render: ([Home]) => <Home />,
					onEnter: async ({ store }) => {
						console.log('on Enter home');
						await store.dispatch(storeActivePage('home'));
						await store.dispatch(getSEO('home'));
						await store.dispatch(getHome());
						console.log('on Enter home / end');
					},
				},
				BrandsRoute,
				FAQsRoute,
				AboutRoute,
				BlogRoute,
			],
		},
		// children route no need i18n
	],
};

export default routes;
