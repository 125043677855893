import React from 'react';

import { storeActivePage, getSEO } from 'models/common';

export default {
	path: '/about',
	components: () => [import(/* webpackChunkName: 'abuot' */ './component')],
	render: ([About]) => <About />,
	onEnter: async ({ store }) => {
		console.log('on Enter about');
		await store.dispatch(storeActivePage('about'));
		await store.dispatch(getSEO('about'));
		console.log('on Enter about / end');
	},
};
