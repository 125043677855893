import { createAction, handleActions } from 'redux-actions';

import { wrapLangFetch } from 'util/api';
import { useRedux } from 'util/hook/redux';

import { storeController, abortController } from './controllers';

export const getSEO = createAction('GET_SEO', (page, id) => async (dispatch, getState) => {
	const {
		controllers: { seo: pageControllers },
	} = getState();

	// abort previous controller
	if ('GET_SEO' in pageControllers) {
		await dispatch(abortController('seo', 'GET_SEO'));
	}
	// new controller
	const controller = new AbortController();
	const { signal } = controller;
	await dispatch(storeController('seo', 'GET_SEO', controller));

	const { data, status, message } = await wrapLangFetch(
		`seo/${page}`,
		{
			method: 'GET',
			signal,
		},
		{
			id,
		},
	);

	if (status !== 200) {
		throw new Error(message);
	}

	return { data: data.seo };
});

export const getIGPost = createAction('FETCH_IG_POST', async () => {
	const { status, message, data } = await wrapLangFetch('igpost', {
		method: 'GET',
	});

	if (status !== 200) {
		throw new Error(message);
	}

	return data.igposts.slice(0, 6);
});

export const storeActivePage = createAction('STORE_ACTIVE_PAGE', page => page);

const reducer = {
	common: handleActions(
		{
			FETCH_IG_POST_FULFILLED: (state, action) => ({
				...state,

				igpost: action.payload,
			}),

			STORE_ACTIVE_PAGE: (state, action) => ({
				...state,

				activePage: action.payload,
			}),

			GET_SEO_FULFILLED: (state, action) => ({
				...state,

				seo: action.payload.data,
			}),
		},
		{
			igpost: [],
			activePage: '',
			seo: {},
		},
	),
};

const selectCommon = state => ({
	...state.common,
});

export const useCommon = () => useRedux(selectCommon);

export default { reducer };
