import React from 'react';

import { storeActivePage, getSEO } from 'models/common';
import { initializeBlog } from 'models/blog';

import { getParameterByName } from 'util/helper';
import DetailRoute from './Detail';

export default {
	path: '/blog',
	components: () => [],
	render: (_, children) => children,
	children: [
		{
			path: '',
			components: () => [import(/* webpackChunkName: 'blog' */ './component')],
			render: ([Blog]) => <Blog />,
			onEnter: async ({ store }) => {
				console.log('on Enter blog');
				const tagId = getParameterByName('tag_id');
				await store.dispatch(storeActivePage('blog'));
				await store.dispatch(getSEO('blog'));
				await store.dispatch(initializeBlog(tagId));
				console.log('on Enter blog / end');
			},
		},
		DetailRoute,
	],
};
