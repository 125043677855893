import React, { useState, useEffect, useRef } from 'react';

import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import LineChat from 'components/molecules/LineChat';
import ScrollUpButton from 'components/atoms/ScrollUpButton';

import { useScroll } from 'util/hook/useScroll';

import styles from './index.css';

const App = ({ children }) => {
	const [scrollUpButtonDisplay, setScrollUpButtonDisplay] = useState(false);
	const scrollY = useScroll();
	const refFooter = useRef(null);

	useEffect(() => {
		if (scrollY) {
			setScrollUpButtonDisplay(
				window.innerHeight + scrollY >= document.body.offsetHeight - refFooter.current.offsetHeight,
			);
		}
	}, [scrollY]);

	return (
		<>
			<Header />
			<div className={styles.children}>{children}</div>
			<LineChat className={styles.lineChatPosition} />
			<ScrollUpButton className={scrollUpButtonDisplay ? styles.active : styles.inActive} />
			<div ref={refFooter}>
				<Footer />
			</div>
		</>
	);
};

export default App;
